/* You can add global styles to this file, and also import other style files */
@import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
@import '~@angular/material/theming';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

$custom-typography: mat-typography-config(
  $font-family: '"Noto Sans JP"',
  $display-4: mat-typography-level(112px, 123px, 300, $letter-spacing: -0.05em),
  $display-3: mat-typography-level(56px, 62px, 400, $letter-spacing: -0.02em),
  $display-2: mat-typography-level(45px, 53px, 400, $letter-spacing: -0.005em),
  $display-1: mat-typography-level(34px, 44px, 400),
  $headline: mat-typography-level(24px, 35px, 400),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(17px, 30px, 400),
  $subheading-1: mat-typography-level(16px, 26px, 400),
  $body-2: mat-typography-level(15px, 26px, 500),
  $body-1: mat-typography-level(15px, 22px, 400),
  $caption: mat-typography-level(13px, 20px, 400),
  $button: mat-typography-level(15px, 15px, 500)
);

@include angular-material-typography($custom-typography);
@include mat-core();
$candy-app-primary: mat-palette($mat-indigo);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);
$candy-app-warn:    mat-palette($mat-red);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include angular-material-theme($candy-app-theme);
@include mat-datetimepicker-theme($candy-app-theme);

html, body { height: 100%; }
body { margin: 0; font-family: "Noto Sans", "Helvetica Neue", sans-serif; }
// body { margin: 0; font-family: "Noto Sans JP", "Helvetica Neue", sans-serif; }
.timepicker-overlay {
  z-index: 1100 !important;
}
.mat-sort-header-container{
  border-bottom: none !important;
}
.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.close-button-outside-dialog {
  position: relative !important;
}

.mat-display-4,
.mat-display-3,
.mat-display-2,
.mat-display-1,
.mat-small,
.mat-caption {
  opacity: 0.54;
}

.mat-h1,
.mat-headline,
.mat-h2,
.mat-title,
.mat-h3,
.mat-subheading-2,
.mat-h4,
.mat-subheading-1,
.mat-body,
.mat-body-1,
.mat-body-strong,
.mat-body-2 {
  opacity: 0.87;
}
